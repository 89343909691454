import * as React from "react";
import "./crop-image.scss";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { withPrefix } from "gatsby";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: 400,
    borderRadius: "15px",
    fontFamily: `"Albert Sans", sans-serif`,
    padding: 24,
  },
}

const cropperBg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC';

export default function CropLogoModal({ open, handleClose, cropperSrc, handleConfirm }) {
  const [cropper, setCropper] = React.useState(<any />);
  const [croppedImage, setCroppedImage] = React.useState('');
  const [modalState, setModalState] = React.useState('crop');

  const [cropSrc, setCropSrc] = React.useState(null);

  React.useEffect(() => {
    const loadImageAndCrop = async () => {
      const logoSrc = cropperSrc;

      const image = new Image();
      image.src = logoSrc;

      image.onload = async () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions to match the image dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        context.drawImage(image, 0, 0);

        // Get image data with non-transparent parts
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const { x, y, width, height } = calculateBoundingBox(imageData);

        // Set canvas dimensions to match the non-transparent bounding box
        canvas.width = width;
        canvas.height = height;

        // Copy the non-transparent portion to the cropped canvas
        context.drawImage(image, x, y, width, height, 0, 0, width, height);

        const croppedSrc = canvas.toDataURL('image/png');
        setCropSrc(croppedSrc);
      };
    };

    loadImageAndCrop();
  }, [cropperSrc]);

  const applyAdjustment = () => {
    const base64data = cropper.getCroppedCanvas().toDataURL();
    setCroppedImage(base64data);
    setModalState('style');
  }

  const [fillColor, setFillColor] = React.useState("#000000");
  const [fillColorClicked, setFillColorClicked] = React.useState(false);
  const handleFillColor = (color) => {
    setFillColor(color);
    setFillColorClicked(true);
  }
  const styleCanvasRef = React.useRef(null);
  React.useEffect(() => {
    if (styleCanvasRef !== null && modalState === 'style') {
      drawStyleCanvas(fillColor, fillColorClicked);
    }
  }, [styleCanvasRef, modalState, fillColor, fillColorClicked])

  const drawStyleCanvas = async (fillColor, fillColorClicked) => {
    const canvas = document.getElementById("style-canvas");
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const image = document.getElementById("style-image");
    if (!image.complete) await new Promise(resolve => image.onload = resolve);

    const rectHeight = 320 / image.width * image.height;
    canvas.height = rectHeight + 30;

    ctx.drawImage(image, 15, 15, 320, rectHeight);
    if (fillColorClicked) {
      ctx.globalCompositeOperation = "source-in";
      ctx.fillStyle = fillColor;
      ctx.fillRect(0, 0, 350, rectHeight + 30);
    }
  }

  const [styleSkipped, setStyleSkipped] = React.useState(false);
  const skipStyle = () => {
    setModalState('underlay');
    setStyleSkipped(true);
    setStyledImage(croppedImage);
  }

  const continueStyle = () => {
    const canvas = document.getElementById("style-canvas");
    const imageData = canvas.toDataURL("image/png");
    setStyledImage(imageData);
    setModalState('underlay');
  }

  const [styledImage, setStyledImage] = React.useState('');
  const [underlayColor, setUnderlayColor] = React.useState("#000000");
  const [underlayColorClicked, setUnderlayColorClicked] = React.useState(false);
  const handleUnderlayColor = (color) => {
    setUnderlayColor(color);
    setUnderlayColorClicked(true);
  }
  const underlayCanvasRef = React.useRef(null);
  React.useEffect(() => {
    if (underlayCanvasRef !== null && modalState === 'underlay') {
      drawUnderlayCanvas(underlayColor, underlayColorClicked, styleSkipped);
    }
  }, [underlayCanvasRef, modalState, underlayColor, underlayColorClicked, styleSkipped])

  const drawUnderlayCanvas = async (underlayColor, underlayColorClicked, styleSkipped) => {
    const canvas = document.getElementById("underlay-canvas");
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const image = document.getElementById("underlay-image");
    if (!image.complete) await new Promise(resolve => image.onload = resolve);

    const rectWidth = styleSkipped ? 320 : 350;
    const rectHeight = rectWidth / image.width * image.height;
    canvas.height = rectHeight + (styleSkipped ? 30 : 0);
    const x = styleSkipped ? 15 : 0;
    const y = styleSkipped ? 15 : 0;

    if (underlayColorClicked) {
      ctx.drawImage(image, x + 4, y + 4, rectWidth, rectHeight);
      ctx.globalCompositeOperation = "source-in";
      ctx.fillStyle = underlayColor;
      ctx.fillRect(x + 4, y + 4, rectWidth, rectHeight);
    }
    ctx.globalCompositeOperation = "source-over";
    ctx.drawImage(image, x, y, rectWidth, rectHeight);
  }

  const skipUnderlay = () => {
    handleConfirm(styledImage);
  }

  const continueUnderlay = () => {
    const canvas = document.getElementById("underlay-canvas");
    const imageData = canvas.toDataURL("image/png");
    handleConfirm(imageData);
  }

  const prevStep = () => {
    setUnderlayColor('#000000');
    setUnderlayColorClicked(false);
    setStyleSkipped(false);
    setStyledImage('');
    setFillColor('#000000');
    setFillColorClicked(false);

    if (modalState === 'underlay') {
      setModalState('style');
    } else if (modalState === 'style') {
      setModalState('crop');
    }
  }

  return (
    <Modal
      id="cropper-modal"
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div style={{ position: 'relative' }}>
        {modalState !== 'crop' && (
          <img 
            src={withPrefix("assets/img/icon-back.svg")} alt="back icon" 
            style={{ 
              margin: '8px 0px 0 -10px', 
              position: 'absolute', top: 0, left: 0,
              width: 34, 
              height: 34, 
              cursor: 'pointer',
            }} 
            onClick={prevStep}
          />
        )}

        <div style={{ textAlign: 'center' }}>
          <h3
            style={{
              display: "inline-block",
              margin: 0,
              padding: 0,
              color: "black",
            }}
          >
            {modalState === 'crop' ? (
              'Adjust Logo'
            ) : modalState === 'style' ? (
              'Style Logo'
            ) : (
              'Add Underlay'
            )}
          </h3>
          <p className="paragraph">
            {modalState === 'crop' ? (
              'Your logo has been resized to meet specs'
            ) : modalState === 'style' ? (
              "Adjust your logo's style"
            ) : (
              'Add an underlay to make your logo pop'
            )}
          </p>          
        </div>

        <span
          style={{ position: 'absolute', top: 0, right: 0 }}
          className="close-button"
          onClick={handleClose}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.974388 24.5156L12 13.49L23.0256 24.5156L24 23.5412L12.9744 12.5156L24 1.49001L23.0256 0.515625L12 11.5412L0.974388 0.515625L0 1.49001L11.0256 12.5156L0 23.5412L0.974388 24.5156Z"
              fill="#070703"
            />
          </svg>
        </span>

        {modalState === 'crop' ? (
          <Cropper
            style={{ width: "100%", height: "100%", backgroundPosition: "50% 50%", backgroundRepeat: "no-repeat", backgroundImage: `url(${withPrefix("assets/img/green-little-balls.gif")})`, backgroundSize: 'contain' }}
            src={cropSrc}
            highlight={false}
            scalable={false}
            cropBoxResizable={true}
            movable={false}
            background={true}
            responsive={true}
            zoomOnWheel={false}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={false}
          />
        ) : modalState === 'style' ? (
          <>
            <div style={{ position: 'relative', backgroundImage: `url(${cropperBg})`}}>
              <canvas ref={styleCanvasRef} id="style-canvas" width={350} height={350} />
              <img id="style-image" src={croppedImage} alt="style image" width={350} height={350} style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }} />
            </div>
            <div className="color-input">
              <h5>Change Color:</h5>
              
              <div style={{ display: 'flex' }}>
                <div className={`defined-color white`} onClick={() => handleFillColor('#ffffff')}></div>
                <div className={`defined-color black`} onClick={() => handleFillColor('#000000')}></div>
                <div className={`defined-color red`} onClick={() => handleFillColor('#ff0000')}></div>
                <div className={`defined-color red`} style={{ marginRight: 0, backgroundImage: `url(${withPrefix("assets/img/bg-color-picker.svg")})` }}>
                  <input
                    type="color"
                    value={fillColor}
                    onChange={(e) => handleFillColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ position: 'relative', backgroundImage: `url(${cropperBg})`}}>
              <canvas ref={underlayCanvasRef} id="underlay-canvas" width={350} height={350} />
              <img id="underlay-image" src={styledImage} alt="underlay image" width={350} height={350} style={{ position: 'absolute', top: 0, left: 0, visibility: 'hidden' }} />
            </div>

            <div className="color-input">
              <h5>Change Color:</h5>
              <div style={{ display: 'flex' }}>
                <div className={`defined-color white`} onClick={() => handleUnderlayColor('#ffffff')}></div>                
                <div className={`defined-color black`} onClick={() => handleUnderlayColor('#000000')}></div>                
                <div className={`defined-color red`} onClick={() => handleUnderlayColor('#ff0000')}></div>                
                <div className={`defined-color red`} style={{ marginRight: 0, backgroundImage: `url(${withPrefix("assets/img/bg-color-picker.svg")})` }}>
                  <input
                    type="color"
                    value={fillColor}
                    onChange={(e) => handleUnderlayColor(e.target.value)}
                  />
                </div>              
              </div>
            </div>
          </>
        )}
      </div>

      {modalState === 'crop' ? (
        <div style={{ marginTop: "15px" }}>
          <button className="btn modal-btn" onClick={applyAdjustment}>
            Apply Adjustment
          </button>
          <button
            className="btn reset-btn-color"
            onClick={() => cropper.reset()}
          >
            Reset
          </button>
        </div>
      ) : modalState === 'style' ? (
        <div style={{ marginTop: "15px" }}>
          <button className="btn modal-btn" onClick={skipStyle}>
            Skip
          </button>
          <button
            className="btn reset-btn-color"
            onClick={continueStyle}
          >
            Continue
          </button>
        </div>
      ) : (
        <div style={{ marginTop: "15px" }}>
          <button className="btn modal-btn" onClick={skipUnderlay}>
            Skip
          </button>
          <button
            className="btn reset-btn-color"
            onClick={continueUnderlay}
          >
            Continue
          </button>
        </div>
      )}
    </Modal>
  )
}

const calculateBoundingBox = (imageData) => {
  let minX = imageData.width;
  let minY = imageData.height;
  let maxX = 0;
  let maxY = 0;

  for (let y = 0; y < imageData.height; y++) {
    for (let x = 0; x < imageData.width; x++) {
      const alpha = imageData.data[(y * imageData.width + x) * 4 + 3];
      if (alpha > 0) {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      }
    }
  }

  const width = maxX - minX + 1;
  const height = maxY - minY + 1;

  return { x: minX, y: minY, width, height };
};
